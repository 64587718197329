import { BaseResponse, instance } from '@app/services/instances';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { MerchantRequest } from '@app/services/merchant-service/report';

export interface GetUserMerchant extends BaseResponse {
  result : {
    rows : {
      phoneNumber: string,
      zipayBalance: number,
      merchantBalance: number
    }[],
    totalCount: number,
    filteredCount : number
  }
}

export const getUsersMerchant = (payload: MerchantRequest): Promise<GetUserMerchant> => {
  return instance.post('/Merchant/MerchantUserList', payload).then((n) => n.data);
};

export const useGetUsersMerchant = (defaultPayload: Partial<MerchantRequest> = {}) => {
  const retrievedObject = localStorage.getItem('auth');
  const parsedObject = JSON.parse(retrievedObject);

  const [payload, setPayload] = useSetState<MerchantRequest>({
    merchantToken: parsedObject.token,
    mid: parsedObject.mid,
    pageNumber: 1,
    pageSize: 500000,
    search: '',
    ...defaultPayload,
  });
  const query = useQuery(['user-merchant', [payload]], () => getUsersMerchant(payload), { keepPreviousData: true });
  return { ...query, payload, setPayload };
};
