import React from 'react';
import clx from 'clsx';
import PropTypes from 'prop-types';
import { CalendarIcon, XIcon } from '@heroicons/react/outline';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import DatePicker, { DayRange } from '@hassanmojab/react-modern-calendar-datepicker';

// InputDate.propTypes = {
//   disabled: PropTypes.bool,
//   error: PropTypes.bool,
//   name: PropTypes.string,
//   onBlur: PropTypes.func,
//   onChange: PropTypes.func,
//   placeholder: PropTypes.string,
//   size: PropTypes.oneOf(['sm', 'base', 'lg']),
//   className: PropTypes.string,
//   inputClassName: PropTypes.string,
//   value: PropTypes.any,
// };

interface InputDateProps {
  // required : required
  id?: string,
  // props?: ReactNode[],
  name?: string,
  type?: string,
  disabled?: boolean,
  error?: any,
  maximumDate?: any
  minimumDate?: any
  style?: any,
  onBlur? : () => void,
  onChange? : (p: { from: string | any; to: string | any }) => void,
  placeholder? : string,
  size? : 'sm' | 'base' | 'lg';
  className? : string,
  inputClassName? :string,
  value? : any
  onClear? :() => void,
  isClear? : boolean,
}

export const InputDate: React.FC<InputDateProps> = ({
  error,
  disabled,
  size = 'base',
  value,
  onChange,
  maximumDate,
  minimumDate,
  onBlur,
  placeholder,
  name,
  style = {},
  onClear = () => {},
  isClear = false,
  ...props
}) => {
  const classNames = clx(
    'appearance-none transition-all duration-300 ease-in-out rounded  px-3 text-gray-800  leading-tight outline-none w-80 focus:border-2  focus:border-blue-500',
    {
      'opacity-75 cursor-not-allowed': disabled,
      'border border-red-400': error,
      'border border-gray-300': !error,
      'h-8 text-xs': size === 'sm',
      'h-10 text-sm': size === 'base',
      'h-12 text-base': size === 'lg',
    },
  );

  const renderCustomInput = ({ ref }) => {
    const tanggal = value?.from
      ? `${value.from ? format(new Date(value.from), 'dd-MM-yyyy') : ''} s/d ${
        value.to ? format(new Date(value.to), 'dd-MM-yyyy') : ''
      }`
      : '';
    return (
      <div className="relative z-0">
        <input
          name={name}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          readOnly
          ref={ref}
          onBlur={onBlur}
          className={classNames}
          value={tanggal}
          style={style}
        />

        <div
          className="absolute text-gray-500 w-10  right-0 top-0 flex justify-center items-center h-full cursor-pointer"
          onClick={onClear}
        >
          {isClear ? (
            <XIcon className="w-5 h-5 text-gray-300" />
          ) : (
            <CalendarIcon className="w-5 h-5 text-gray-300" />
          )}
        </div>
      </div>
    );
  };

  function parseValue(ISODate) {
    if (!ISODate) {
      return null;
    }
    const originalDate = parseISO(ISODate);

    return {
      year: originalDate.getFullYear(),
      month: originalDate.getMonth() + 1,
      day: originalDate.getDate(),
    };
  }

  return (
    <div>
      <DatePicker
        onChange={(val : DayRange) => {
          const from = val.from != null
            ? format(
              new Date(val.from?.year, val.from?.month - 1, val.from?.day),
              'yyyy-MM-dd',
            )
            : null;
          const to = val.to != null
            ? format(
              new Date(val.to?.year, val.to?.month - 1, val.to?.day),
              'yyyy-MM-dd',
            )
            : null;

          onChange({ from, to });
        }}
        value={{ from: parseValue(value?.from), to: parseValue(value?.to) }}
        colorPrimary="#0EA5E9"
        colorPrimaryLight="#E0F2FE"
        renderInput={renderCustomInput}
        slideAnimationDuration="0.3s"
        inputPlaceholder="Select a day"
        shouldHighlightWeekends
        calendarClassName="datepicker-calendar"
        inputClassName="datepicker-input"
        {...props}
      />
    </div>
  );
};
