export * as dateUtil from './date.util';
export * as commonUtil from './common.util';
export * as localStorageUtil from './localstorage.util';

interface FormatNumberOpts {
  currency?: 'IDR';
  notation?: 'id-ID';
}

export const formatNumber = (value: number | string, opts: FormatNumberOpts = {}) => {
  let v = (value || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (opts.notation === 'id-ID') {
    v = Intl.NumberFormat('id-ID', { notation: 'compact', compactDisplay: 'long' }).format(Number(value || 0));
    v = v.split(' ').map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');
  }
  if (opts.currency === 'IDR') v = `Rp ${v}`;

  return v;
};
