import { format } from 'date-fns';
import moment from 'moment';
import dayjs from 'dayjs';

export const formatDate = (date: number | string | Date) => {
  const formatValue = typeof date === 'string' ? new Date(date) : date;

  return format(formatValue, 'YYYY-MM-DD');
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const formatDateMoment = (date: number | string | Date, format: string = 'YYYY-MM-DD') => {
  const formatValue = typeof date === 'string' ? new Date(date) : date;
  return moment(formatValue).format(format);
};

export function formatDateDayId(timeStamps) {
  let hari = dayjs(timeStamps).format('dddd');

  if (hari === 'Monday') {
    hari = 'Senin';
  }
  if (hari === 'Tuesday') {
    hari = 'Selasa';
  }
  if (hari === 'Wednesday') {
    hari = 'Rabu';
  }
  if (hari === 'Thursday') {
    hari = 'Kamis';
  }
  if (hari === 'Friday') {
    hari = 'Jumat';
  }
  if (hari === 'Saturday') {
    hari = 'Sabtu';
  }
  if (hari === 'Sunday') {
    hari = 'Minggu';
  }
  return `${hari} , ${dayjs(timeStamps).format('DD-MM-YYYY hh:mm:ss')}`;
}

export function formatDateDayEng(timeStamps) {
  const hari = dayjs(timeStamps).format('dddd');
  return `${hari} , ${dayjs(timeStamps).format('DD-MM-YYYY, hh:mm:ss')} WIB`;
}
