import { EyeIcon, PencilAltIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

export * from './ButtonActions';

interface ActionProps {
  to?: string;
  disabled?: boolean;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  type?: 'default' | 'info' | 'error'
}

const BaseAction: React.FC<ActionProps> = ({ children, to, disabled, onClick, size = 'md', type = 'default' }) => {
  const className = clsx({
    'w-6 p-[2px] rounded-sm': size === 'sm',
    'w-8 p-[4px] rounded-md': size === 'md',
    'w-10 p-[6px] rounded-lg': size === 'lg',
    'text-gray-400 cursor-not-allowed': disabled,
    'text-gray-600 cursor-pointer hover:bg-gray-200': !disabled && type === 'default',
    'text-lightBlue-500 cursor-pointer hover:bg-lightBlue-100': !disabled && type === 'info',
    'text-red-500 cursor-pointer hover:bg-red-100': !disabled && type === 'error',
  });

  if (!disabled && to) {
    return (
      <Link to={to}>
        <button className={className}>{children}</button>
      </Link>
    );
  }

  if (!disabled && onClick) {
    return (
      <button onClick={onClick} className={className}>{children}</button>
    );
  }

  return (
    <div className={className}>
      {children}
    </div>
  );
};

export const ViewAction = (props: ActionProps) => {
  return (
    <BaseAction type="info" {...props}>
      <EyeIcon />
    </BaseAction>
  );
};

export const DeleteAction = (props: ActionProps) => {
  return (
    <BaseAction type="error" {...props}>
      <TrashIcon />
    </BaseAction>
  );
};

export const UpdateAction = (props: ActionProps) => {
  return (
    <BaseAction type="info" {...props}>
      <PencilAltIcon />
    </BaseAction>
  );
};

export const CloseAction = (props: ActionProps) => {
  return (
    <BaseAction type="default" {...props}>
      <XIcon />
    </BaseAction>
  );
};
