import React from 'react';

interface AlertProps {
  props? : any
}

export const Alert : React.FC<AlertProps> = ({ props }) => {
  return (
    <div
      className="bg-red-50 border-l-4 border-red-400 text-red-600 p-3 text-sm mb-5"
      role="alert"
    >
      <p>{props.children}</p>
    </div>
  );
};
