import React, { useState } from 'react';
import clx from 'clsx';

const Header = ({ headers, onSorting }) => {
  const [sortingField, setSortingField] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const onSortingChange = (field) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  function Sorter({ order, active }) {
    const upClass = clx('', {
      'text-lightBlue-500': order === 'asc' && active,
    });

    const downClass = clx('-mt-1', {
      'text-lightBlue-500': order === 'desc' && active,
    });
    return (
      <div className="text-gray-200">
        <div className={upClass}>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <UpIcon />
        </div>
        <div className={downClass}>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <DownIcon />
        </div>
      </div>
    );
  }

  // @ts-ignore
  return (
    <thead className="border-t mt-5 border-b w-full">
      <tr className="h-16 font-semibold">
        {headers.map(({ name, field, sortable }) => (
          <th
            className={`text-xs  ${
              false && ''
            } h-auto font-normal uppercase text-gray-600 text-left px-2 cursor-pointer hover:bg-lightBlue-50`}
            rowSpan={1}
            colSpan={1}
            key={name}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            <div className="flex justify-between w-full items-center">
              <div className="flex justify-between w-full">{name}</div>
              {
              sortable && (
              <Sorter active={field} order={sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'} />
              )
            }
            </div>
            {/* {sortingField && sortingField === field && ( */}
            {/*  sortingOrder === 'asc' */}
            {/*  // eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            {/*    ? DownIcon() */}
            {/*  // eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            {/*    : UpIcon())} */}
          </th>
        ))}
      </tr>
    </thead>
  );
};

function UpIcon() {
  return (
    <svg
      viewBox="0 0 1024 1024"
      focusable="false"
      data-icon="caret-up"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
    </svg>
  );
}

function DownIcon() {
  return (
    <svg
      viewBox="0 0 1024 1024"
      focusable="false"
      data-icon="caret-down"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
    </svg>
  );
}

export default Header;
