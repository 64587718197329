import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';

export const useToast = (opts: UseToastOptions = {}) => {
  const toast = useChakraToast({
    duration: 5000,
    position: 'top-right',
    ...opts,
  });

  return toast;
};
