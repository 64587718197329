import { HStack } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { commonUtil } from '@app/utils';

interface PaginationProps {
  page?: number; // current page
  totalItem?: number;
  pageSize?: number;
  onChange?: (v: number) => void;
  boundaryCount?: number; // left/right number count
  siblingCount?: number; // center sibling number count
}

export const Pagination = ({ page = 1, totalItem = 0, pageSize = 10, boundaryCount = 1, siblingCount = 1, onChange }: PaginationProps) => {
  const count = Math.ceil(totalItem / pageSize); // total page
  const hidden = !totalItem;

  const startPages = commonUtil.range(1, Math.min(boundaryCount, count));
  const endPages = commonUtil.range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count);

  const siblingsStart = Math.max(Math.min(page - siblingCount, count - boundaryCount - siblingCount * 2 - 1), boundaryCount + 2);
  const siblingsEnd = Math.min(Math.max(page + siblingCount, boundaryCount + siblingCount * 2 + 2), endPages.length > 0 ? endPages[0] - 2 : count - 1);

  // e.g. itemList = ['previous', 1, 2, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next']
  const itemList: (string | number)[] = [
    'previous',
    ...startPages,

    // start ellipsis
    ...(siblingsStart > boundaryCount + 2 ? ['ellipsis'] : boundaryCount + 1 < count - boundaryCount ? [boundaryCount + 1] : []),

    // sibling pages
    ...commonUtil.range(siblingsStart, siblingsEnd),

    // end ellipsis
    ...(siblingsEnd < count - boundaryCount - 1 ? ['ellipsis'] : count - boundaryCount > boundaryCount ? [count - boundaryCount] : []),

    ...endPages,
    'next',
  ];

  // map the button type to its page number
  const buttonPage = (type: string) => {
    if (type === 'previous') return page - 1;
    if (type === 'next') return page + 1;
    return null;
  };

  return (
    <HStack spacing="4px" hidden={hidden}>
      {itemList.map((item, itemIdx) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let child: typeof item | ReactElement<any, any> = item;
        let isDisabled = false;
        const toPage = typeof item === 'number' ? item : buttonPage(item);

        if (typeof child !== 'number') {
          if (child === 'ellipsis') child = '...';
          if (child === 'previous') {
            child = <ChevronLeftIcon className="w-4" />;
            isDisabled = page === 1;
          }
          if (child === 'next') {
            child = <ChevronRightIcon className="w-4" />;
            isDisabled = page === count;
          }
        }

        const isActive = item === page;
        const isDecorator = item === 'ellipsis';

        return (
          <div
            key={itemIdx}
            className={clsx('flex items-center justify-center select-none w-8 h-8 bg-white border rounded-md', {
              'text-lightBlue-500 border-lightBlue-500 cursor-default': isActive && !isDecorator && !isDisabled,
              'text-gray-600 cursor-pointer hover:text-lightBlue-500 hover:border-lightBlue-300': !isActive && !isDecorator && !isDisabled,
              'text-gray-600 cursor-default': isDecorator,
              'text-gray-600 cursor-not-allowed bg-gray-100': isDisabled,
            })}
            onClick={() => !isDecorator && !isDisabled && onChange && onChange(toPage)}
          >
            {child}
          </div>
        );
      })}
    </HStack>
  );
};
