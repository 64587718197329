import React from 'react';
import { Input, InputProps } from '@chakra-ui/react';
import { useField } from 'formik';
import clsx from 'clsx';
import { FormHelper } from './FormHelper';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts
export const FormInputDisabled = ({ value = '', size = 'md' }: Pick<InputProps, 'value' | 'size'>) => {
  return (
    <span
      className={clsx('w-full inline-flex items-center cursor-default outline-none bg-gray-200 h-10', {
        'text-base px-4 py-2 rounded-md': size === 'md',
      })}
    >
      {value}
    </span>
  );
};

export const FormInput = (props: InputProps) => {
  const [field, meta] = useField(props.name!);

  const isError = meta.error && meta.touched;

  return (
    <>
      {props.isDisabled ? <FormInputDisabled size={props.size} value={field.value} /> : <Input {...field} isInvalid={!!isError} {...props} />}
      <FormHelper variant="error">{isError ? meta.error : ' '}</FormHelper>
    </>
  );
};
