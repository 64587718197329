import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { BasePaginationPayload, BasePaginationResponse, instance } from '../instances';

export interface GetDiagnosisPopulationsResponse extends BasePaginationResponse {
  data: {
    id: number;
    code: string;
    diagnosis_name: string;
    total_of_population: number;
    created_at: Date;
    updated_at: Date;
  }[]
}

export const getDiagnosisPopulations = (payload: BasePaginationPayload): Promise<GetDiagnosisPopulationsResponse> => {
  return instance.get('/diagnosis-population', { params: payload }).then((n) => n.data);
};

export const useGetDiagnosisPopulations = (defaultPayload: Partial<BasePaginationPayload> = {}) => {
  const [payload, setPayload] = useSetState<BasePaginationPayload>({
    pageNUmber: 10,
    pageSize: 1,
    keyword: '',
    ...defaultPayload,
  });

  const query = useQuery(['diagnosis-population', [payload]], () => getDiagnosisPopulations(payload), { keepPreviousData: true });

  return { ...query, payload, setPayload };
};
