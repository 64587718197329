import { PieChart, Pie, Sector, Cell, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import { useGetDashboardSummary,
  useGetDiagnosisPopulations,
  useGetMerchantHistoryTransaction } from '@app/services/merchant-service';
import { CircularProgress } from '@chakra-ui/react';
import { formatNumber } from '@app/utils';
import { useMemo } from 'react';
import { InfoCard } from './InfoCard';
import './Home.css';

const utilisasiData: { name: string; color: string; total: number; cost: number }[] = [
  { name: 'Kapitasi', color: '#749FF8', total: 0, cost: 0 },
  { name: 'FFS', color: '#C9D9FC', total: 0, cost: 0 },
];

const ChartLabel = (props: { name: string, color: string }) => {
  return (
    <div className="flex items-center mb-2">
      <div className="w-3 h-3 rounded-full" style={{ background: props.color }} />
      <p className="ml-2 text-xs text-gray-800">{props.name}</p>
    </div>
  );
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    // payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 4) * cos;
  const sy = cy + (outerRadius + 4) * sin;
  const mx = cx + (outerRadius + 16) * cos;
  const my = cy + (outerRadius + 16) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 8;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 6}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        className="text-xs font-semibold"
      >
        {`${formatNumber(value, { notation: 'id-ID' })}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 6}
        y={ey}
        dy={14}
        textAnchor={textAnchor}
        fill="#999"
        className="text-xs font-semibold text-gray-400"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export const HomePage = () => {
  // const { data: resps } = useGetMerchantHistoryTransaction();
  // console.log(resps);

  // const { data: resp, isLoading } = useGetDashboardSummary();
  // @ts-ignore
  // const { data: respDiag, isLoading: isLoadingDiag } = useGetDiagnosisPopulations({ limit: 10 });
  //
  // const data = resp?.data;
  // const diag = respDiag?.data;
  //
  // if (data && Array.isArray(data.utiization_by_participant_category) && Array.isArray(data.cost_by_participant_category)) {
  //   utilisasiData[0].total = data.utiization_by_participant_category[0]?.total_utilization || 0;
  //   utilisasiData[1].total = data.utiization_by_participant_category[1]?.total_utilization || 0;
  //
  //   utilisasiData[0].cost = data.cost_by_participant_category[0]?.total_cost || 0;
  //   utilisasiData[1].cost = data.cost_by_participant_category[1]?.total_cost || 0;
  // }

  // const providerData = useMemo(() => {
  //   const total: { name: string; ffs: number; kapitasi: number }[] = [];
  //   const cost: { name: string; ffs: number; kapitasi: number }[] = [];
  //
  //   if (data) {
  //     console.log(data);
  //     data.utilization_by_provider_participant_category?.forEach((n) => {
  //       const idx = total.findIndex((x) => x.name === n.service_provider);
  //       const cat = n.participant_category.toLowerCase();
  //       if (idx > -1) {
  //         total[idx][cat] = n.total_utilization;
  //       } else {
  //         total.push({ name: n.service_provider, ffs: 0, kapitasi: 0, [cat]: n.total_utilization });
  //       }
  //     });
  //
  //     data.cost_by_provider_participant_category?.forEach((n) => {
  //       const idx = cost.findIndex((x) => x.name === n.service_provider);
  //       const cat = n.participant_category.toLowerCase();
  //       if (idx > -1) {
  //         cost[idx][cat] = n.total_cost;
  //       } else {
  //         cost.push({ name: n.service_provider, ffs: 0, kapitasi: 0, [cat]: n.total_cost });
  //       }
  //     });
  //   }
  //   return { total, cost };
  // }, [data]);

  return (
    <div>
      {/* <div className="flex items-center"> */}
      {/*  <p className="text-xl text-gray-800">Dashboard</p> */}
      {/*  <div className="flex-1" /> */}
      {/*  /!* <ImportExcel path="/import-service-monitoring" /> *!/ */}
      {/* </div> */}

      {/* {(isLoading || isLoadingDiag) && (!data || !diag) ? ( */}
      {/*  <div className="flex justify-center items-center w-full min-h-[400px]"> */}
      {/*    <CircularProgress isIndeterminate color="blue.500" /> */}
      {/*  </div> */}
      {/* ) : ( */}
      {/*  <> */}
      {/*    <div className="flex mt-6 gap-x-8"> */}
      {/*      <div className="flex-1 px-4 py-4 bg-white rounded-[22px]"> */}
      {/*        <p className="text-[25px] font-semibold text-gray-600">427</p> */}
      {/*        <p className="text-xs font-medium text-gray-400">Provider Rekanan Yakes di Indonesia</p> */}
      {/*        <img src="/assets/svg/provider-maps.svg" className="mx-auto" /> */}
      {/*      </div> */}

      {/*      <div className="flex-1 p-4 bg-white rounded-[22px] gap-4 grid grid-cols-2"> */}
      {/*        <InfoCard label="Jumlah Kunjungan Total" icons="/assets/icons/people-group.svg" value={formatNumber(data?.total_visit || 0)} variant="blue" /> */}
      {/*        <InfoCard label="Jumlah Kunjungan Rawat Jalan" icons="/assets/icons/drugs.svg" value={formatNumber(data?.total_visit_out_patient || 0)} variant="green" /> */}
      {/*        <InfoCard label="Jumlah Kunjungan Rawat Inap" icons="/assets/icons/hospital-bed.svg" value={formatNumber(data?.total_visit_inpatient || 0)} variant="purple" /> */}
      {/*        <InfoCard label="Jumlah Pasien (Populasi yang Aktif Berobat)" icons="/assets/icons/syringe.svg" value={formatNumber(data?.total_utilization || 0)} variant="orange" /> */}
      {/*      </div> */}
      {/*    </div> */}

      {/*    <div className="flex mt-8 gap-x-8"> */}
      {/*      <div className="max-w-[520px] flex-1 p-4 2xl:p-6 bg-white rounded-[22px] min-h-[200px] relative overflow-hidden"> */}
      {/*        <img className="absolute top-0 right-0 w-20 h-20 rotate-180 bg-cover" src="/assets/svg/union-shape.svg" /> */}
      {/*        <img className="w-10 p-1 rounded-full" style={{ background: '#E6E2F8' }} src="/assets/icons/stethoscope-purlple.svg" /> */}
      {/*        <p className="mt-2 text-base font-semibold text-gray-600"> */}
      {/*          Diagnosis Terbanyak */}
      {/*          <br /> */}
      {/*          Pada Populasi */}
      {/*        </p> */}
      {/*        <table className="w-full mt-4 text-xs font-normal text-gray-400"> */}
      {/*          <tbody> */}
      {/*            <tr> */}
      {/*              <td className="py-2">Jenis</td> */}
      {/*              <td className="px-1 py-2 text-right">Total Populasi</td> */}
      {/*              /!* <td className="px-1 py-2 text-right">FFS</td> *!/ */}
      {/*            </tr> */}
      {/*            {diag?.map((item, itemKey) => ( */}
      {/*              <tr className="border-t" key={itemKey}> */}
      {/*                <td className="py-2">{item.diagnosis_name}</td> */}
      {/*                <td className="px-1 py-2 font-semibold text-right text-gray-800">{formatNumber(item.total_of_population)}</td> */}
      {/*                /!* <td className="px-1 py-2 font-semibold text-right text-purple-800">{formatNumber(item.total_of_population)}</td> *!/ */}
      {/*              </tr> */}
      {/*            ))} */}
      {/*          </tbody> */}
      {/*        </table> */}
      {/*      </div> */}

      {/*      <div className="flex-1 flex p-4 2xl:p-6 bg-white justify-between rounded-[22px] mac:min-w-[846px]"> */}
      {/*        <div className="min-w-[182px] w-[182px] mr-4"> */}
      {/*          <img className="w-10 p-1 rounded-full" src="/assets/icons/hospital.svg" style={{ background: '#F0F8FF' }} /> */}
      {/*          <p className="mt-2 text-base font-semibold text-gray-600">Layanan Pensiunan Pertamina</p> */}
      {/*          <div className="p-4 mt-4 rounded-[22px] transition-shadow hover:shadow-xl"> */}
      {/*            <img className="p-2 rounded-lg w-9" style={{ background: '#E1E9FF' }} src="/assets/icons/sigma-math-blue.svg" /> */}
      {/*            <p className="mt-4 text-2xl font-semibold text-gray-600">{formatNumber(data?.total_utilization || 0, { notation: 'id-ID' })}</p> */}
      {/*            <p className="text-xs font-medium text-gray-600">Total Utilisasi</p> */}
      {/*          </div> */}
      {/*          <div className="p-4 mt-4 rounded-[22px] transition-shadow hover:shadow-xl"> */}
      {/*            <img className="p-2 rounded-lg w-9" style={{ background: '#FDF2E4' }} src="/assets/icons/wallet-orange.svg" /> */}
      {/*            <p className="mt-4 text-2xl font-semibold text-gray-600">{formatNumber(data?.total_cost || 0, { notation: 'id-ID' })}</p> */}
      {/*            <p className="text-xs font-medium text-gray-600">Total Biaya</p> */}
      {/*          </div> */}
      {/*        </div> */}

      {/*        <div className="flex-1 flex flex-wrap gap-3 max-w-[840px] h-[fit-content]"> */}
      {/*          <div className="p-4 overflow-hidden border rounded-[22px] flex-1 min-w-[280px] max-h-[260px]"> */}
      {/*            <p className="text-xs font-semibold text-gray-700 max-w-[134px]">Utilisasi Berdasarkan Jenis Kepesertaan</p> */}
      {/*            <div className="flex items-center mt-4"> */}
      {/*              <PieChart width={172} height={154}> */}
      {/*                <Pie */}
      {/*                  data={utilisasiData} */}
      {/*                  outerRadius={42} */}
      {/*                  dataKey="total" */}
      {/*                  fill="#8884d8" */}
      {/*                  activeIndex={utilisasiData.map((_, i) => i)} */}
      {/*                  activeShape={renderActiveShape} */}
      {/*                > */}
      {/*                  {utilisasiData.map((item, idx) => <Cell key={idx} fill={item.color} />)} */}
      {/*                </Pie> */}
      {/*              </PieChart> */}
      {/*              <div className="ml-4"> */}
      {/*                <p className="mb-3 text-xs text-gray-400">Jenis Kepesertaan</p> */}
      {/*                {utilisasiData.map((item) => <ChartLabel key={item.name} name={item.name} color={item.color} />)} */}
      {/*              </div> */}
      {/*            </div> */}
      {/*          </div> */}

      {/*          <div className="p-4 overflow-hidden border rounded-[22px] flex-1 min-w-[280px] max-h-[260px]"> */}
      {/*            <p className="text-xs font-semibold text-gray-700 max-w-[246px]">Utilisasi Berdasarkan Layanan Faskes & Jenis Kepesertaan</p> */}
      {/*            <div className="flex gap-3 mt-5 mb-1"> */}
      {/*              <p className="mr-2 text-xs text-gray-400">Jenis kepesertaan</p> */}
      {/*              <ChartLabel name="FFS" color="#C9D9FC" /> */}
      {/*              <ChartLabel name="Kapitasi" color="#749FF8" /> */}
      {/*            </div> */}

      {/*            <BarChart data={providerData.total} width={246} height={152} layout="vertical"> */}
      {/*              <CartesianGrid horizontal={false} strokeDasharray="3 3" /> */}
      {/*              <XAxis type="number" tickLine={false} axisLine={false} tick={{ fontSize: 10 }} /> */}
      {/*              <YAxis dataKey="name" type="category" tickLine={false} axisLine={false} tick={{ fontSize: 10 }} /> */}
      {/*              <Tooltip /> */}
      {/*              <Bar dataKey="ffs" fill="#C9D9FC" /> */}
      {/*              <Bar dataKey="kapitasi" fill="#749FF8" /> */}
      {/*            </BarChart> */}
      {/*          </div> */}

      {/*          <div className="p-4 overflow-hidden border rounded-[22px] flex-1 min-w-[280px] max-h-[260px]"> */}
      {/*            <p className="text-xs font-semibold text-gray-700 max-w-[134px]">Biaya Berdasarkan Jenis Kepesertaan</p> */}
      {/*            <div className="flex items-center mt-4"> */}
      {/*              <PieChart width={172} height={154}> */}
      {/*                <Pie */}
      {/*                  data={utilisasiData} */}
      {/*                  outerRadius={42} */}
      {/*                  innerRadius={18} */}
      {/*                  dataKey="cost" */}
      {/*                  fill="#8884d8" */}
      {/*                  activeIndex={utilisasiData.map((_, i) => i)} */}
      {/*                  activeShape={renderActiveShape} */}
      {/*                > */}
      {/*                  {utilisasiData.map((item, idx) => <Cell key={idx} fill={item.color} />)} */}
      {/*                </Pie> */}
      {/*              </PieChart> */}
      {/*              <div className="ml-4"> */}
      {/*                <p className="mb-3 text-xs text-gray-400">Jenis Kepesertaan</p> */}
      {/*                {utilisasiData.map((item) => <ChartLabel key={item.name} name={item.name} color={item.color} />)} */}
      {/*              </div> */}
      {/*            </div> */}
      {/*          </div> */}

      {/*          <div className="p-4 overflow-hidden border rounded-[22px] flex-1 min-w-[280px] max-h-[260px]"> */}
      {/*            <p className="text-xs font-semibold text-gray-700 max-w-[246px]">Biaya Berdasarkan Layanan Faskes & Jenis Kepesertaan</p> */}
      {/*            <div className="flex gap-3 mt-5 mb-1"> */}
      {/*              <p className="mr-2 text-xs text-gray-400">Jenis kepesertaan</p> */}
      {/*              <ChartLabel name="FFS" color="#C9D9FC" /> */}
      {/*              <ChartLabel name="Kapitasi" color="#749FF8" /> */}
      {/*            </div> */}

      {/*            <BarChart data={providerData.cost} width={246} height={152} layout="vertical"> */}
      {/*              <CartesianGrid horizontal={false} strokeDasharray="3 3" /> */}
      {/*              <XAxis type="number" tickLine={false} axisLine={false} tick={{ fontSize: 10 }} /> */}
      {/*              <YAxis dataKey="name" type="category" tickLine={false} axisLine={false} tick={{ fontSize: 10 }} /> */}
      {/*              <Tooltip /> */}
      {/*              <Bar dataKey="ffs" fill="#C9D9FC" /> */}
      {/*              <Bar dataKey="kapitasi" fill="#749FF8" /> */}
      {/*            </BarChart> */}
      {/*          </div> */}
      {/*        </div> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </> */}
      {/* )} */}
    </div>
  );
};
