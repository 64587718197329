import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuthStore } from '@app/stores';
import { useEffect } from 'react';
import { Sidebar } from './Sidebar';

export const BaseLayout: React.FC = ({ children }) => {
  // const auth = useAuthStore();
  //
  // useEffect(() => {
  //   if (auth.auth === null) {
  //     auth.init();
  //   }
  // }, []);
  //   let width = isMenuExpanded ? '200px' : '60px';

  return (
    <div className="flex">
      {/* <div */}
      {/*  className="flex" */}
      {/*  style={{ gridTemplateColumns: `${width} minmax(0, 1fr)` }} */}
      {/* /> */}
      <Sidebar />
      <div className="flex flex-col overflow-x-auto w-full">
        <div className="border-b-[1px] w-full sticky h-[60px] z-20 bg-white right-0 top-0">
          {/* <div className="flex justify-center h-full pr-8">
            <BellIcon className="w-6 ml-auto text-gray-400" />
          </div> */}
        </div>
        <div style={{ background: '#F5F6F8' }} className="flex-1 px-8 pt-6 pb-8">
          {children}
        </div>
      </div>
    </div>
  );
};

export const BaseLayoutRoute = ({ component: Component, ...args }: RouteProps) => {
  const token = JSON.parse(localStorage.getItem('auth'));
  return token ? (
    <Route
      {...args}
      render={(props) => (
        <BaseLayout>
          <Component {...props} />
        </BaseLayout>
      )}
    />
  ) : (
  // eslint-disable-next-line react/jsx-no-undef
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};
