import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CustomChakra } from './CustomChakra';
import { AppRouter } from './Router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => (
  <CustomChakra>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
    </QueryClientProvider>
  </CustomChakra>
);
