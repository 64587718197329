import { CircularProgress, Fade } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React from 'react';

interface TableProps {
  loading?: boolean;
  fetching?: boolean;
  error?: boolean;
  empty?: boolean;
}

const TablePlaceHolder: React.FC<{ in: boolean }> = (props) => {
  return (
    <Fade in={props.in} unmountOnExit>
      <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-100 bg-opacity-75 min-h-[260px]">
        <div className="px-8 py-4 bg-white rounded-md">
          {props.children}
        </div>
      </div>
    </Fade>
  );
};

export const Table: React.FC<TableProps> = ({ loading, fetching, error, empty, children } = { loading: false, fetching: false, error: false, empty: true }) => {
  return (
    <div className="relative">
      <table className="editor_listing_table">{children}</table>

      <TablePlaceHolder in={(loading && empty) || (fetching && !empty)}>
        <CircularProgress isIndeterminate color="blue.500" />
      </TablePlaceHolder>

      <TablePlaceHolder in={error}>
        <p className="text-gray-600">Ada Kesalahan</p>
      </TablePlaceHolder>

      <TablePlaceHolder in={empty && !loading && !fetching && !error}>
        <p className="text-gray-600">Data tidak ditemukan</p>
      </TablePlaceHolder>
    </div>
  );
};

interface SorterProps {
  active?: boolean;
  onClick?: () => void;
  order?: 'asc' | 'desc';
}

export const Sorter = ({ active, onClick, order }: SorterProps) => {
  return (
    <div className="flex flex-col justify-center text-gray-400 cursor-pointer" onClick={onClick}>
      <ChevronUpIcon className={clsx('w-3', { 'text-lightBlue-500': order === 'asc' && active })} />
      <ChevronDownIcon className={clsx('w-3', { 'text-lightBlue-500': order === 'desc' && active })} />
    </div>
  );
};

export interface ThProps extends React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement> {
  columnKey: string;
  onToggleSort?: (columnKey: string) => void;
  flexDisplay?: boolean;
  sort?: {
    column: string;
    order?: 'asc' | 'desc'
  }
}

export const Th: React.FC<ThProps> = ({ sort, columnKey, onToggleSort, children, className, ...args }) => {
  const isSortActive = sort && columnKey && !!onToggleSort;
  return (
    <th
      {...args}
      onClick={() => isSortActive && onToggleSort && onToggleSort(columnKey)}
      className={clsx('h-auto px-6 py-4 text-sm text-left text-gray-600', className, { 'cursor-pointer hover:bg-gray-50': !!onToggleSort })}
    >
      <div className="flex justify-between w-full">
        <div className="flex items-center">{children}</div>
        {isSortActive && (
          <Sorter active={sort?.column === columnKey} order={sort?.order} />
        )}
      </div>
    </th>
  );
};

export const Td: React.FC<React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>> = ({ children, ...args }) => {
  return <td className="px-3 py-5 text-sm" {...args}>{children}</td>;
};
