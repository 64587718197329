import React from 'react';
import { Loading } from '@app/components/Loading';

export const FullPageLoader = () => {
  return (
    <div className="fp-container">
      <Loading />
    </div>
  );
};

// export default FullPageLoader;
