import { Button, ButtonProps } from '@chakra-ui/react';
import { TrashIcon, XIcon } from '@heroicons/react/outline';

export const BatalkanAction = (props: ButtonProps) => {
  return (
    <Button
      background="white"
      textColor="gray.600"
      leftIcon={<XIcon className="w-6" />}
      {...props}
    >
      {props.children || 'Batalkan'}
    </Button>
  );
};

export const HapusAction = (props: ButtonProps) => {
  return (
    <Button
      background="white"
      textColor="red.500"
      leftIcon={<TrashIcon className="w-6" />}
      {...props}
    >
      {props.children || 'Hapus'}
    </Button>
  );
};
