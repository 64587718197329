import { InfoCard } from '@app/pages/Home/InfoCard';
import { TableHeader, Pagination, Search } from '@app/components/Datatable';
import { Table, Th, Td, PaginationInfo, Label, InputDate } from '@app/components';
import { Input } from '@app/components/Forms/Input';
import { SearchIcon } from '@heroicons/react/outline';
import { formatRupiah } from '@app/utils/formatRupiah';
import React, { useMemo, useState } from 'react';
import { GetMerchantTransaction,
  useGetUsersMerchant } from '@app/services/merchant-service';

export const Users = () => {
  // const { data, isFetching, isLoading, payload, setPayload, refetch } = useGetProviderSummary();
  const [dataTrx, setDataTrx] = useState<Partial<GetMerchantTransaction>>({});
  const [totalItems, setTotalItems] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalMerchantBalance, settotalMerchantBalance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { data, isFetching, isLoading, payload, setPayload, refetch } = useGetUsersMerchant();
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: 'No', field: 'id', sortable: false },
    { name: 'Phone Number', field: 'phoneNumber', sortable: true },
    { name: 'Zipay Balance', field: 'zipayBalance', sortable: false },
    { name: 'Merchant Balance', field: 'merchantBalance', sortable: false },
  ];

  // eslint-disable-next-line consistent-return
  const commentsData = useMemo(() => {
    console.log('get data mana ?');
    if (data?.result) {
      setTotalUser(data.result.totalCount);
      let computedComments = data.result.rows;

      if (search) {
        computedComments = computedComments.filter(
          (comment) => comment.phoneNumber.toLowerCase().includes(search.toLowerCase())
                || comment.merchantBalance.toString().toLowerCase().includes(search.toLowerCase()) || comment.zipayBalance.toString().toLowerCase().includes(search.toLowerCase()),

        );
      }

      const mBalance = data.result.rows
        .reduce((accumulator, item: any) => {
          return accumulator + item.merchantBalance;
        }, 0);

      settotalMerchantBalance(mBalance);
      setTotalItems(computedComments.length);
      // Sorting comments
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        computedComments = computedComments.sort(
          (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]),
        );
      }

      // Current Page slice
      return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
      );
    }
  }, [data?.result.rows, currentPage, search, sorting]);

  return (
    <div>
      <div className="flex-1 p-4 bg-white rounded-[22px] gap-4 grid grid-cols-2">
        <InfoCard label="Total User" icon="/assets/icon/people-group.svg" value={totalUser.toString()} variant="blue" />
        <InfoCard label="Total Balance" icon="/assets/icon/wallet-orange.svg" value={`${formatRupiah(totalMerchantBalance)}`} variant="green" />
        {/* <InfoCard label="Jumlah Kunjungan Rawat Inap" icons="/assets/icons/hospital-bed.svg" value="100" variant="purple" /> */}
        {/* <InfoCard label="Jumlah Pasien (Populasi yang Aktif Berobat)" icons="/assets/icons/syringe.svg" value="150" variant="orange" /> */}
      </div>
      <div className="p-5">
        <div className="bg-white px-5 border border-gray-200 rounded-lg">
          <div className="flex space-x-2 mt-7 mb-7">
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="lg"
              variant="outline"
              placeholder="Cari"
              rightIcon={
                <SearchIcon className="w-5 h-5 text-gray-600" />
                }
              tabIndex="0"
            />
            {/* <Select */}
            {/*  className="mt-1" */}
            {/*  name="is_active" */}
            {/*  placeholder="Status" */}
            {/*  id="is_active" */}
            {/* > */}
            {/*  <option value="kategori">Kategori</option> */}
            {/*  <option value="jaminan">Jaminan</option> */}
            {/* </Select> */}
          </div>

          <Table loading={isLoading} fetching={isFetching} empty={!(data?.result.rows || []).length}>
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            {/* <thead className="border-b"> */}
            {/*  <tr className="h-12 font-semibold"> */}
            {/*    <Th columnKey="id">No.</Th> */}
            {/*    <Th columnKey="name" sort={sort} onToggleSort={(columnKey) => setSorting({ columnKey })}>Name</Th> */}
            {/*    <Th columnKey="email">Email</Th> */}
            {/*    <Th columnKey="body">Body</Th> */}
            {/*  </tr> */}
            {/* </thead> */}
            <tbody>
              {commentsData?.map((comment, index) => (
                <tr className="border-b border-gray-200 hover:bg-gray-50" key={index}>
                  <Th columnKey={index.toString()}>
                    {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                  </Th>
                  <Td>{comment.phoneNumber}</Td>
                  <Td>{formatRupiah(comment.zipayBalance)}</Td>
                  <Td>{formatRupiah(comment.merchantBalance)}</Td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="p-5 flex justify-between">
            <div className="flex space-x-5 items-center">
              {/* <div className="flex space-x-2"> */}
              {/*  <div className="flex items-center text-sm text-gray-800"> */}
              {/*    Show */}
              {/*  </div> */}
              {/*  <div className="w-20"> */}
              {/*    <Select */}
              {/*      name="page_size" */}
              {/*      onChange={(event) => { */}
              {/*        setPage(1); */}
              {/*        setPageSize(event.target.value); */}
              {/*      }} */}
              {/*      value={pageSize} */}
              {/*    > */}
              {/*      <option value="5">5</option> */}
              {/*      <option value="10">10</option> */}
              {/*      <option value="20">20</option> */}
              {/*    </Select> */}
              {/*  </div> */}
              {/* </div> */}
              <PaginationInfo
                currentPage={currentPage}
                totalItems={totalItems}
                pageSize={ITEMS_PER_PAGE}
              />
            </div>
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
