import { useState, useCallback } from 'react';
import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import { useDebounce } from 'react-use';

interface SimpleSearchInputProps {
  onSubmit?: (search: string) => void;
  className?: string;
}

export const SimpleSearchInput = (props: SimpleSearchInputProps) => {
  const [search, setSearch] = useState('');

  const handleSearch = useCallback(() => {
    if (props.onSubmit) props.onSubmit(search);
  }, [search]);

  const handleReset = useCallback(() => {
    setSearch('');
    handleSearch();
  }, []);

  useDebounce(() => {
    handleSearch();
  }, 500, [search]);

  return (
    <div className={clsx('flex items-center', { [props.className]: props.className })}>
      <InputGroup>
        <Input placeholder="Search..." width="280px" value={search} onChange={(e) => setSearch(e.target.value)} onKeyUp={(e) => e.key === 'Enter' && handleSearch()} />
        <InputRightElement hidden={!search.length}>
          <XIcon className="w-6 text-gray-400 cursor-pointer" onClick={handleReset} />
        </InputRightElement>
      </InputGroup>
      <IconButton aria-label="submit-filter" colorScheme="green" borderRadius="md" marginLeft="2" icon={<SearchIcon className="w-6" />} onClick={handleSearch} />
    </div>
  );
};
