import React, { SVGProps, useEffect, useState } from 'react';
import { Collapse, Text } from '@chakra-ui/react';
// eslint-disable-next-line max-len
import { ChevronDownIcon, ChevronUpIcon, ColorSwatchIcon, BeakerIcon, BookOpenIcon, LibraryIcon, ClipboardCheckIcon, ClipboardCopyIcon, GlobeIcon, GlobeAltIcon, HomeIcon, RefreshIcon } from '@heroicons/react/outline';
import { Link, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { useBaseLayoutStore } from '@app/stores';
import { DocumentReportIcon, LogoutIcon, UsersIcon } from '@heroicons/react/solid';

interface MenuItemProps {
  url: string;
  title: string;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  divider?: boolean;
  paths?: string[];
  children?: Pick<MenuItemProps, 'url' | 'title' | 'paths'>[]
}

const menus: MenuItemProps[] = [
  // {
  //   url: '/',
  //   title: 'Dashboard',
  //   icons: HomeIcon,
  //   paths: ['/'],
  // },
  {
    url: '/transaction',
    title: 'Transaction',
    icon: DocumentReportIcon,
    paths: ['/transaction'],
  },
  {
    url: '/users',
    title: 'Users',
    icon: UsersIcon,
    paths: ['/users'],
  },
  {
    url: '/settlement-report',
    title: 'Settlement Report',
    icon: DocumentReportIcon,
    paths: ['/settlement-report'],
  },
  {
    url: '/cash-out',
    title: 'Cash Out',
    icon: RefreshIcon,
    paths: ['/cash-out'],
  },
  {
    url: '/logout',
    title: 'Logout',
    icon: LogoutIcon,
    paths: ['/logout'],
  },

  // {
  //   url: '#',
  //   title: 'Pengaturan',
  //   icons: AdjustmentsIcon,
  //   children: [
  //     {
  //       url: '/about',
  //       title: 'Tentang Aplikasi',
  //       paths: ['/about'],
  //     },
  //     {
  //       url: '/sign-out',
  //       title: 'Keluar',
  //       paths: ['/sign-out'],
  //     },
  //   ],
  // },
];

export const Sidebar = React.memo(() => {
  const routeMatch = useRouteMatch();
  const { isSlight, expandedMenu, setIsSlight, setExpandedMenu } = useBaseLayoutStore((state) => ({
    isSlight: state.isSlight,
    setIsSlight: state.setIsSlight,
    expandedMenu: state.expandedMenu,
    setExpandedMenu: state.setExpandedMenu,
  }));

  const [companyName, setCompanyName] = useState('');
  const [mid, setMid] = useState('');

  useEffect(() => {
    // setPayload({ mid: '' });
    const retrievedObject = localStorage.getItem('auth');
    const parsedObject = JSON.parse(retrievedObject);
    // mid(parsedObject.mid);
    setMid(parsedObject.mid);
    setCompanyName(parsedObject.name);
  });

  const [loading, setLoading] = useState(false);
  return (
    <div
      className={clsx('sticky bg-white top-0 z-20 h-screen border-r border-gray-200 flex flex-col', { 'min-w-[256px]': !isSlight, 'w-[60px]': isSlight })}
      // style={{ boxShadow: '2px 0px 6px rgba(0, 0, 0, 0.06)' }}
    >
      {/* logo section */}
      <div className="flex items-center h-[60px] mx-auto mt-5">
        <div className="w-[150px] flex items-center justify-center">
          <img src="/logo.png" />
        </div>
        {/* {!isSlight && <img src="/assets/icons/logo_desc.svg" />} */}
      </div>

      {/* profile section */}
      <div className="flex items-center my-6">
        <div className={clsx('w-[68px] flex items-center justify-center ml-[8px]', { 'ml-0': isSlight })}>
          <img src="/assets/img/user.png" className="w-[48px] h-[48px] rounded-full" />
        </div>
        {!isSlight && (
          <div>
            <Text fontSize="sm" className="text-gray-800">{mid}</Text>
            <Text fontSize="xs" className="text-gray-500">{companyName}</Text>
          </div>
        )}
      </div>

      {/* menu section */}
      <div className="mt-4">
        {menus.map((menu, i) => {
          const MenuIcon = menu.icon;

          if (menu.divider) return <div className="w-full h-[1px] bg-gray-300 my-4" key={i} />;

          if (menu.children?.length) {
            const isActive = menu.children.some((n) => n.paths?.includes(routeMatch.path));
            const isExpanded = (expandedMenu === i) || isActive;

            return (
              <React.Fragment key={i}>
                <div
                  key={i}
                  onClick={() => {
                    if (isSlight) {
                      setExpandedMenu(i);
                      setIsSlight(false);
                    } else if (!isActive) setExpandedMenu(isExpanded ? null : i);
                  }}
                  className={clsx('relative flex items-center h-12 text-sm text-gray-700 cursor-pointer hover:bg-blueGray-100 hover:text-lightBlue-500', {
                    'text-lightBlue-500 bg-blueGray-100': isActive,
                    'justify-center': isSlight,
                    'pl-5 pr-3': !isSlight,
                  })}
                >
                  {MenuIcon && <MenuIcon className="w-5" />}
                  {!isSlight && <Text className="ml-3 mr-auto capitalize">{menu.title}</Text>}
                  {!isSlight && (isExpanded ? <ChevronUpIcon className="w-4" /> : <ChevronDownIcon className="w-4" />)}
                  {isActive && <div className="absolute left-0 h-full bg-lightBlue-500 w-[4px]" />}
                </div>
                <Collapse in={(isExpanded && !isSlight)} animateOpacity>
                  <div className="text-sm text-gray-700">
                    {menu.children.map((child, childKey) => {
                      const isChildActive = child.paths?.includes(routeMatch.path);
                      return (
                        <Link
                          to={child.url}
                          key={childKey}
                          className={clsx('pl-[52px] flex items-center h-10 cursor-pointer hover:text-lightBlue-500', {
                            'text-lightBlue-500 bg-blueGray-50': isChildActive,
                          })}
                        >
                          {child.title}
                        </Link>
                      );
                    })}
                  </div>
                </Collapse>
              </React.Fragment>
            );
          }

          const isActive = menu.paths?.includes(routeMatch.path);

          return (
            <Link
              to={menu.url}
              key={i}
              className={clsx('relative flex items-center h-12 text-sm text-gray-700 cursor-pointer hover:bg-blueGray-100 hover:text-lightBlue-500', {
                'text-lightBlue-500 bg-blueGray-100': isActive,
                'justify-center': isSlight,
                'px-5': !isSlight,
              })}
            >
              {MenuIcon && <MenuIcon className="w-5" />}
              {!isSlight && <Text className="ml-3 capitalize">{menu.title}</Text>}
              {isActive && <div className="absolute left-0 h-full bg-lightBlue-500 w-[4px]" />}
            </Link>
          );
        })}
      </div>

      {/* toggle section */}
      <div
        onClick={() => setIsSlight(!isSlight)}
        className={clsx('flex items-center h-12 mt-auto text-sm text-gray-400 cursor-pointer hover:text-lightBlue-500', {
          'text-lightBlue-500': isSlight,
          'justify-center': isSlight,
          'px-5': !isSlight,
        })}
      >
        <ColorSwatchIcon className="w-5" />
        {!isSlight && <Text className="ml-3">Toggle sidebar</Text>}
      </div>
    </div>
  );
});
