import { BaseResponse, instance } from '@app/services/instances';

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse extends BaseResponse {
  result: {
    id: number;
    mid: string;
    status: string;
    token: string;
  };
}

export function postLogin(values: LoginRequest): Promise<LoginResponse> {
  return instance.post('/Merchant/Merchantlogin', values).then((n) => n.data);
}
