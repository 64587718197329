import clsx from 'clsx';
import React from 'react';

interface FormHelperProps {
  variant?: 'default' | 'info' | 'error';
}

export const FormHelper: React.FC<FormHelperProps> = ({ variant = 'default', children }) => {
  return (
    <div
      className={clsx('text-xs ml-2 mt-1 transition-opacity', {
        'text-gray-500': variant === 'default',
        'text-lightBlue-500': variant === 'info',
        'text-red-500': variant === 'error',
      })}
    >
      {children === ' ' ? (
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
      ) : children}
    </div>
  );
};
