import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Input } from '@app/components/Forms/Input';
import { Label, Alert } from '@app/components';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { postLogin } from '@app/services/merchant-service/login';
import { useToast } from '@app/hooks';
import { useAuthStore } from '@app/stores';
import { useHistory } from 'react-router-dom';
import * as localStorageUtil from '../../utils/localstorage.util';

export const AuthPage = () => {
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Email cannot be blank'),
    password: Yup.string().required('Password cannot be blank'),
  });

  const toast = useToast();
  const auth = useAuthStore();
  const history = useHistory();

  type SignValues = Yup.Asserts<typeof LoginSchema>;

  const [errorMessage, setErrorMessage] = useState(null);
  const [showPass, setShowPass] = useState(false);

  const [formData, setFormData] = useState({
    user: {
      username: '',
      password: '',
    },
  });

  // const handleChange = (e) => {
  //   setFormData({
  //     user: {
  //       ...formData.user,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };

  const onSubmit = async (payload : SignValues) => {
    try {
      const res : any = await postLogin(payload);
      console.log('tes', res.result);
      localStorageUtil.set('auth', res.result);
      // await auth.init();
      history.push('/');
    } catch (error) {
      console.log(error);
      toast({ status: 'error', description: 'Username / Password wrong!' });
    }
  };
  return (
    <div className="min-h-screen flex">
      <div className="md:w-1/2 hidden md:block  bg-gradient-to-r from-green-400 to-blue-500 has-bg-img">
        <img
          className="bg-img"
          style={{ height: '100vh', width: '100%' }}
          src="/background.png"
          alt=""
        />
      </div>
      <div className="md:w-1/2 w-full  bg-white  flex flex-col items-center px-10 md:px-32 py-10">
        <div className="w-full  h-full flex justify-center flex-col">
          <div className="w-1/2">
            <img src="/logo.png" alt="" />
          </div>
          <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mt-14 space-y-5">
                  <div>{errorMessage && <Alert>{errorMessage}</Alert>}</div>
                  <div>
                    <Label htmlFor="username">Email</Label>
                    <Input
                      name="username"
                      disabled={isSubmitting}
                      error={errors.username && touched.username}
                      id="username"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      placeholder="Nama Pengguna"
                      tabIndex="0"
                      variant="filled"
                      size="lg"
                    />
                    {errors.username && touched.username && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.username}
                    </span>
                    )}
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <Label htmlFor="password">Kata Sandi</Label>
                      <span className="text-lightBlue-500 text-base">
                        Lupa Kata Sandi ?
                      </span>
                    </div>

                    <Input
                      name="password"
                      tabIndex="0"
                      disabled={isSubmitting}
                      error={errors.password && touched.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      variant="filled"
                      placeholder="Kata Sandi"
                      rightIcon={
                                                    showPass ? (
                                                      <EyeOffIcon
                                                        onClick={() => setShowPass(false)}
                                                        className="text-gray-500 cursor-pointer"
                                                      />
                                                    ) : (
                                                      <EyeIcon
                                                        onClick={() => setShowPass(true)}
                                                        className="text-gray-500 cursor-pointer"
                                                      />
                                                    )
                                                }
                      type={showPass ? 'text' : 'password'}
                      size="lg"
                    />
                    {errors.password && touched.password && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.password}
                    </span>
                    )}
                  </div>
                </div>
                <div className="mt-12 space-y-6">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Login
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
