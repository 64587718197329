import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  );
};

export const AuthLayoutRoute = ({ component: Component, ...args }: RouteProps) => {
  const token = JSON.parse(localStorage.getItem('auth'));
  return !token ? (
    <Route
      {...args}
      render={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  )
    : (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
};
