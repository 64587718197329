/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { commonUtil } from '@app/utils';
import axios from 'axios';

const CryptoJS = require('crypto-js');

const { baseUrl } = commonUtil.getEnv();

export const instance = axios.create({
  baseURL: 'https://apidev.zipay.id/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const keyPromiseCbc = '9F%J@NcRfUjXn2r5u8x/A?D#GyKaPdSg';

const AESCBCEncrypt = (iv, txt) => {
  const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(keyPromiseCbc), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
  });

  return cipher.toString();
};

export const instanceCo = () => {
  const tmp = new Date().getTime().toString().padEnd(16, '0'); // timestamp 16 digit

  const user = 'dev';
  const pass = 'supersecretpassword';
  const encryptUser = AESCBCEncrypt(tmp, user);
  const encryptPass = AESCBCEncrypt(tmp, pass);

  return axios.create({
    baseURL: 'https://depositdev.zipay.id/v2',
    headers: {
      'Content-Type': 'application/json',
      timestamp: tmp,
      Authorization:
          `Basic ${
            Buffer.from(`${encryptUser}:${encryptPass}`).toString('base64')}`,
    },
    auth: {
      username: encryptUser,
      password: encryptPass,
    },
  });
};

export interface BaseResponse {
  message: string;
  status: string;
  error: boolean;
}

export interface BaseParamRequest {
  mid: string;
  merchantToken: string;
  search :string;
  startDate :string;
  endDate :string;
  pageNumber : number;
  pageSize : number;
}

export interface BasePaginationPayload {
  pageNUmber: number;
  pageSize: number;
  keyword: string;
}

export interface BasePaginationResponse extends BaseResponse {
  pagination: {
    current_page: number;
    limit: number;
    perpage_found: number;
    total_found: number;
    total_page: number;
    search: {
      paginate: {
        page: string;
        limit: string;
      };
      keyword: string;
    }
  };
}
