import {useQuery} from 'react-query';
import {BaseResponse, instance} from '../instances';

export interface GetDashboardSummaryResponse extends BaseResponse {
    data: {
        total_visit: number;
        total_visit_inpatient: number;
        total_visit_out_patient: number;
        total_utilization: number;
        total_cost: number;
        utiization_by_participant_category: {
            participant_category: string;
            total_utilization: number;
        }[];
        cost_by_participant_category: {
            participant_category: string;
            total_cost: number;
        }[];
        cost_by_provider_participant_category: {
            service_provider: string;
            participant_category: string;
            total_cost: number;
        }[];
        utilization_by_provider_participant_category: {
            service_provider: string;
            participant_category: string;
            total_utilization: number;
        }[];
    }
}

export const getDashboardSummary = (): Promise<GetDashboardSummaryResponse> => {
    return instance.get('/dashboard-summary').then((n) => n.data);
};

export const useGetDashboardSummary = () => {
    const query = useQuery(['dashboard-summary', []], () => getDashboardSummary(), {keepPreviousData: true});

    console.log(query)

    return {...query};
};
