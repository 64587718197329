import { useEffect, useState } from 'react';
import { localStorageUtil } from '@app/utils';
import { useHistory } from 'react-router-dom';
import { Alert } from '@app/components';
import { toast } from '@chakra-ui/react';
import { useToast } from '@app/hooks';

export const LogoutPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const toast = useToast();
  const history = useHistory();
  useEffect(() => {
    localStorageUtil.clear();
    // eslint-disable-next-line no-restricted-globals
    toast({ status: 'success', description: 'You has been sucessfully logout' });
    history.push('/login');
    // @ts-ignore
  });
  return (
    <div />
  );
};
