import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useQueryParams = <Type>() => {
  const location = useLocation();

  const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });

  return params as unknown as Type;
};
