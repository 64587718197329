import { ExportToExcel, InputDate, Label, PaginationInfo, Table, Td, Th } from '@app/components';
import React, { useCallback, useEffect, useState } from 'react';
import { InfoCard } from '@app/pages/Home/InfoCard';
import { formatRupiah } from '@app/utils/formatRupiah';
import { Formik } from 'formik';
import { Input } from '@app/components/Forms/Input';
import { SearchIcon } from '@heroicons/react/outline';
import { Pagination, TableHeader } from '@app/components/Datatable';
import { formatDateDayEng } from '@app/utils/date.util';
import { useGetSettlementReport } from '@app/services/merchant-service/settlement';
import { useGetCashoutHistory } from '@app/services/merchant-service/Cashout';
import { int } from 'framer-motion/types/render/dom/value-types/type-int';
import useDebounce from '@app/hooks/useDebounce';

export const CashOutPage = () => {
  // const { data, isFetching, isLoading, payload, setPayload, refetch } = useGetProviderSummary();
  const [search, setSearch] = useState('');

  const { data, isFetching, isLoading, payload, setPayload, refetch } = useGetCashoutHistory();

  const [dates, setDates] = useState({
    from: null,
    to: null,
  });

  const headers = [
    { name: 'No', field: 'id', sortable: false },
    { name: 'M-Order Id', field: 'merchantOrderId', sortable: true },
    { name: 'Uniq M-Order Id', field: 'merchantOrderId', sortable: true },
    { name: 'Reference Id', field: 'referenceId', sortable: true },
    { name: 'Bank Account No', field: 'bankAccountNo', sortable: true },
    { name: 'Bank Name', field: 'bankName', sortable: true },
    { name: 'Deducted Amount', field: 'deductedAmount', sortable: false },
    { name: 'Admin Fee', field: 'adminFee', sortable: false },
    { name: 'Transferred Amount', field: 'transferedAmount', sortable: false },
    { name: 'Trx Status', field: 'transactionStatus', sortable: true },
    { name: 'Created Date', field: 'createdAt', sortable: false },
    { name: 'Updated Date', field: 'updatedAt', sortable: true },
  ];

  // eslint-disable-next-line eqeqeq
  // if (search != '') {
  //   setPayload({ searchTerm: search });
  // }
  //

  // const debouncedKeyword = useDebounce(search, 500);
  // const changeHandler = (event) => {
  //   console.log(event);
  //   setSearch(event.target.value);
  // };
  // const debouncedChangeHandler = useCallback(
  //   useDebounce(changeHandler, 300),
  //   [],
  // );

  const onSubmit = (values : any) => {
    try {
      setDates(values.trx_date);
      setPayload({ startDate: values.trx_date.from, endDate: values.trx_date.to, page: '1', limit: '100000' });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="p-5">
        <div className="bg-white px-5 py-5">
          <Label font="bold" size="lg" htmlFor="username">Filter</Label>
          <div className="mt-5 mb-5 border border-b border-gray-200" />
          <Formik
            initialValues={{ trx_date: {
              from: null,
              to: null,
            } }}
            enableReinitialize
            onSubmit={(values) => {
              onSubmit({
                trx_date: {
                  from: values.trx_date.from,
                  to: values.trx_date.to,
                },
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4">
                    <InputDate
                      value={values.trx_date}
                      minimumDate={{
                        year: new Date().getFullYear() - 10,
                        month: 1,
                        day: 1,
                      }}
                      maximumDate={{
                        year: new Date().getFullYear() + 100,
                        month: new Date().getMonth() + 1,
                        day: new Date().getDate(),
                      }}
                      onChange={(value) => setFieldValue('trx_date', value)}
                      placeholder="Transaction Date"
                      id="transaction_date"
                    />
                    {/* <SelectR */}
                    {/*  name="category" */}
                    {/*  id="category" */}
                    {/*  placeholder="Kepesertaan" */}
                    {/*  value="ok" */}
                    {/* > */}
                    {/*  <option value="">Pilih</option> */}
                    {/*  <option value="kapitasi">Kapitasi</option> */}
                    {/*  <option value="ffs">ffs</option> */}
                    {/* </SelectR> */}

                    {/* </div> */}
                  </div>
                  <div className="flex space-x-4 items-center">
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                      Search
                    </button>
                    <ExportToExcel apiData={data?.data?.rows} fileName={`AVOKADO_SETTLEMENT_REPORT - ${(dates?.from) ? (`${dates?.from} ' TO '`) : ''} ${(dates?.to) ? dates?.to : ''}`} />
                    {/* <button onClick={click} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"> */}
                    {/*  Export */}
                    {/* </button> */}
                  </div>
                </div>
              </form>
            )}
          </Formik>
          {/* <InputDate */}
          {/*  id="effective_date" */}
          {/*  value={defaultValue} */}
          {/*    // value={(values)=> { */}
          {/*    //   return values.effective_date */}
          {/*    // }} */}
          {/*  onChange={(value) => { */}
          {/*    console.log(value); */}
          {/*  }} */}
          {/*  placeholder="Tanggal Efektif" */}
          {/* /> */}
        </div>
      </div>
      <div className="p-5">
        <div className="bg-white px-5 border border-gray-200 rounded-lg">
          {/* <div className="flex space-x-2 mt-7 mb-7"> */}
          {/*  <Input */}
          {/*    value={search} */}
          {/*    onChange={(e) => setSearch(e.target.value)} */}
          {/*    size="lg" */}
          {/*    variant="outline" */}
          {/*    placeholder="Cari" */}
          {/*    rightIcon={ */}
          {/*      <SearchIcon className="w-5 h-5 text-gray-600" /> */}
          {/*                  } */}
          {/*    tabIndex="0" */}
          {/*  /> */}
          {/*  /!* <Select *!/ */}
          {/*  /!*  className="mt-1" *!/ */}
          {/*  /!*  name="is_active" *!/ */}
          {/*  /!*  placeholder="Status" *!/ */}
          {/*  /!*  id="is_active" *!/ */}
          {/*  /!* > *!/ */}
          {/*  /!*  <option value="kategori">Kategori</option> *!/ */}
          {/*  /!*  <option value="jaminan">Jaminan</option> *!/ */}
          {/*  /!* </Select> *!/ */}
          {/* </div> */}
          <div className="overflow-x-auto">
            <Table loading={isLoading} fetching={isFetching} empty={!(data?.data.rows || []).length}>
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setPayload({ sort: order })}
              />
              <tbody>
                {data?.data.rows.map((comment, index) => (
                  <tr className="border-b border-gray-200 hover:bg-gray-50" key={index}>
                    <Th columnKey={index.toString()}>
                      {(parseInt(payload.page, 10) - 1) * parseInt(payload.limit, 10) + index + 1}
                    </Th>
                    <Td>{comment?.merchantOrderId}</Td>
                    <Td>{comment?.uniqueMerchantOrderId}</Td>
                    <Td>{comment?.referenceId}</Td>
                    <Td>{comment?.bankAccountNo}</Td>
                    <Td>{comment?.BankMaster.name}</Td>
                    <Td>{comment?.deductedAmount}</Td>
                    <Td>{formatRupiah(comment?.adminFee)}</Td>
                    <Td>{formatRupiah(comment?.transferedAmount)}</Td>
                    <Td>{comment?.trxStatus}</Td>
                    <Td>{formatDateDayEng(comment?.createdAt)}</Td>
                    <Td>{formatDateDayEng(comment?.updatedAt)}</Td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="p-5 flex justify-between">
              <div className="flex space-x-5 items-center">
                {/* <div className="flex space-x-2"> */}
                {/*  <div className="flex items-center text-sm text-gray-800"> */}
                {/*    Show */}
                {/*  </div> */}
                {/*  <div className="w-20"> */}
                {/*    <Select */}
                {/*      name="page_size" */}
                {/*      onChange={(event) => { */}
                {/*        setPage(1); */}
                {/*        setPageSize(event.target.value); */}
                {/*      }} */}
                {/*      value={pageSize} */}
                {/*    > */}
                {/*      <option value="5">5</option> */}
                {/*      <option value="10">10</option> */}
                {/*      <option value="20">20</option> */}
                {/*    </Select> */}
                {/*  </div> */}
                {/* </div> */}
                <PaginationInfo
                  currentPage={payload.page || 0}
                  totalItems={data?.data?.count}
                  pageSize={payload.limit}
                />
              </div>
              <Pagination
                total={data?.data?.count || 0}
                itemsPerPage={parseInt(payload.limit, 10)}
                currentPage={parseInt(payload.page, 10)}
                onPageChange={(p) => setPayload({ page: p })}
              />

              {/* <Pagination itemsPerPage={payload.page} total={data?.meta?.totalDocs || 0} pageSize={payload.limit} onChange={(p) => setPayload({ page: p })} /> */}

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
