import { BaseResponse, instance, instanceCo } from '@app/services/instances';
import { GetMerchantTransaction, MerchantRequest } from '@app/services/merchant-service/report';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { getSettlementReport } from '@app/services/merchant-service/settlement';

const querystring = require('querystring');

export interface CashOutRequest {
  startDate: string;
  endDate: string;
  sort: string;
  limit: string;
  page: string;
  mid: string
  merchantToken: string
  searchTerm: string
}

export interface CashOutHistory extends BaseResponse {
  data: {
    count: number
    rows : {
      bankAccountNo: string;
      adminFee: number;
      merchantOrderId: string;
      uniqueMerchantOrderId: string;
      trxStatus: string;
      transferedAmount: number;
      deductedAmount: number;
      referenceId: string;
      createdAt: string;
      updatedAt: string;
      BankMaster: {
        name: string;
      }
    }[]
  }
}

export const getCashOutHistory = (payload: CashOutRequest): Promise<CashOutHistory> => {
  const inc = instanceCo();
  // const data = JSON.stringify(payload);
  // const data = querystring.stringify(payload);

  return inc.post('/cash-out/history', payload).then((n) => n.data);
};

export const useGetCashoutHistory = (defaultPayload: Partial<CashOutRequest> = {}) => {
  const retrievedObject = localStorage.getItem('auth');
  const parsedObject = JSON.parse(retrievedObject);

  const [payload, setPayload] = useSetState<CashOutRequest>({
    endDate: '',
    limit: '10',
    merchantToken: parsedObject.token,
    mid: parsedObject.mid,
    page: '1',
    searchTerm: '',
    sort: 'DESC',
    startDate: '',
    ...defaultPayload,
  });

  const query = useQuery(['cashout-history', [payload]], () => getCashOutHistory(payload), { keepPreviousData: true });
  return { ...query, payload, setPayload };
};
