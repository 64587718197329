/* eslint-disable no-param-reassign */
export const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getEnv = () => {
  return ({
    baseUrl: process.env.REACT_APP_BASE_URL,
    appVersion: process.env.npm_package_version,
    appEnv: process.env.NODE_ENV,
  });
};

export const groupBy = <T>(arr: T[], keys: (keyof T)[]): { [key: string]: T[] } => {
  return arr.reduce((storage, item) => {
    const objKey = keys.map((key) => `${item[key]}`).join(':');
    if (storage[objKey]) {
      storage[objKey].push(item);
    } else {
      storage[objKey] = [item];
    }
    return storage;
  }, {} as { [key: string]: T[] });
};
