import clx from 'clsx';
import React from 'react';

interface LabelProps {
  // required : required
  required? : boolean;
  children? : React.ReactNode
  htmlFor? : string
  className?: string,
  size?: 'xs' | 'sm' | 'base' | 'lg';
  font?: 'normal' | string
}

export const Label : React.FC<LabelProps> = ({
  size = 'base',
  font = 'normal',
  htmlFor,
  required,
  children,
}) => {
  const labelClass = clx('block text-gray-600  mb-2', {
    'text-xs': size === 'xs',
    'text-sm': size === 'sm',
    'text-base': size === 'base',
    'text-lg': size === 'lg',
    'font-normal': font === 'normal',
    'font-bold': font === 'bold',
  });

  return (
    <label className={labelClass} htmlFor={htmlFor}>
      {children}
      {' '}
      {required && <span className="text-red-600">*</span>}
    </label>
  );
};
