// import { userService } from '@app/services';
// import { LoginResponse } from '@app/services/auth-service';
// import { Permission } from '@app/services/users-service';
import { localStorageUtil } from '@app/utils';
import create from 'zustand';
import { LoginResponse } from '@app/services/merchant-service/login';

export {};

interface AuthStore {
  auth: LoginResponse['result'] | null;
  setAuth: (value: AuthStore['auth']) => void;
  loading: boolean;
  //   permissions: Permission[] | null;
  init: () => Promise<void>;
}

export const useAuthStore = create<AuthStore>((set) => ({
  auth: localStorageUtil.get('auth'),
  setAuth: (value) => set({ auth: value }),
  loading: false,
  // permissions: null,
  init: async () => {
    // set({ loading: true });

    // shoud set to permissions
    // await userService.getAccess();

    // set({ loading: false, permissions: [] });
  },
}));
