import { BasePaginationPayload, BaseResponse, instance } from '@app/services/instances';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import * as localStorageUtil from '@app/utils/localstorage.util';

export interface MerchantRequest {
  mid: string;
  merchantToken: string;
  search :string;
  pageNumber : number;
  pageSize : number;
}

export interface GetMerchantTransaction extends BaseResponse {
  result : {
    rows : {
      createdDate : string,
      hp : string,
      merchantOrderID : string,
      productCode : string,
      referenceId : string,
      sellingPrice : string,
      transctionStatus : string,
      bankCode : string,
      updateDate : string
    }[],
    totalCount: number,
    filteredCount : number
  }
}

export const getMerchantHistoryTransaction = (payload: MerchantRequest): Promise<GetMerchantTransaction> => {
  return instance.post('/Merchant/MerchantTransactionHistory', payload).then((n) => n.data);
};

export const useGetMerchantHistoryTransaction = (defaultPayload: Partial<MerchantRequest> = {}) => {
  const retrievedObject = localStorage.getItem('auth');
  const parsedObject = JSON.parse(retrievedObject);

  const [payload, setPayload] = useSetState<MerchantRequest>({
    merchantToken: parsedObject.token,
    mid: parsedObject.mid,
    pageNumber: 1,
    pageSize: 500000,
    search: '',
    ...defaultPayload,
  });
  const query = useQuery(['merchant-history-transaction', [payload]], () => getMerchantHistoryTransaction(payload), { keepPreviousData: true });
  return { ...query, payload, setPayload };
};

// createdDate: "2021-12-30T13:18:19.153"
// hp: "810248376989865"
// merchantOrderID: "AVOKADO-202112301318170061140"
// productCode: "va"
// referenceId: "AVOKADO-202112301318170061140"
// sellingPrice: 10000
// transctionStatus: "Completed"

// bankCode: "BBBA"
// createdDate: "2022-01-05T15:09:49.127"
// hp: "810429106937823"
// merchantOrderID: "AVOKADO-202201051509480201504"
// productCode: "va"
// referenceId: "AVOKADO-202201051509480201504"
// sellingPrice: 530000
// transctionStatus: "Pending"
// updateDate: "2022-01-05T15:09:49.127"

// base_url: "https://api.zipay.id",
//     post_register: "/api/Merchant/register",
//     post_login: "/api/Merchant/Merchantlogin",
//     post_history: "/api/Merchant/MerchantTransactionHistory",
//     post_void: "/api/Merchant/Void",
//     get_balance: "/api/Merchant/balance",
//     post_retry: "/api/Merchant/payment",
//     get_user: "/api/Merchant/MerchantUserList",
