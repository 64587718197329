import { HeroIcon } from '@app/types';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React from 'react';

interface TagProps {
  status?: 'default' | 'success' | 'error';
  label?: string;
  icon?: boolean | HeroIcon;
  className?: string;
}

const defaultProps: TagProps = {
  status: 'default',
  label: '',
  icon: false,
  className: '',
};

export const Tag = React.memo(({ status, label, icon: Icon, className }: TagProps = defaultProps) => {
  let LabelIcon: HeroIcon | null = null;

  if (Icon) {
    if (typeof Icon === 'function') {
      LabelIcon = Icon;
    } else {
      switch (status) {
        case 'success':
          LabelIcon = CheckIcon;
          break;
        case 'error':
          LabelIcon = XIcon;
          break;
        default:
          break;
      }
    }
  }

  return (
    <span
      className={clsx('flex items-center', className, {
        'text-gray-600': status === 'default',
        'text-green-600': status === 'success',
        'text-red-600': status === 'error',
      })}
    >
      {LabelIcon && (
        <LabelIcon className={clsx('w-6 mr-2')} />
      )}
      {label}
    </span>
  );
});
