import React from 'react';

export const PaginationInfo = ({ currentPage, pageSize, totalItems }) => {
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  return (
    <div className="text-sm text-gray-700">
      Menampilkan
      {' '}
      {startIndex + 1}
      {' '}
      sampai
      {' '}
      {endIndex + 1}
      {' '}
      dari
      {' '}
      {totalItems}
      {' '}
      data
    </div>
  );
};
