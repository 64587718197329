import React, { useEffect, useState, useMemo } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import clx from 'clsx';

const PaginationComponent = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
}) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function getPager(total, currentPage, itemsPerPage) {
    // default to first page
    // eslint-disable-next-line no-param-reassign
    currentPage = currentPage || 1;

    // default page size is 10
    // eslint-disable-next-line no-param-reassign
    itemsPerPage = itemsPerPage || 10;

    // calculate total pages
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const totalPages = Math.ceil(total / itemsPerPage);

    let startPage; let
      endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      // eslint-disable-next-line no-lonely-if
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, total - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i,
    );
    return {
      total,
      currentPage,
      itemsPerPage,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }
  const pager = getPager(total, currentPage, itemsPerPage);

  const paginationItems = useMemo(() => {
    const pages = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        // <Pagination.Item
        //   key={i}
        //   active={i === currentPage}
        //   onClick={() => onPageChange(i)}
        // >
        //   {i}
        // </Pagination.Item>,

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <PaginationItem
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i.toString()}
        </PaginationItem>,
      );
    }

    return pages;
  }, [totalPages, currentPage]);

  if (totalPages === 0) return null;

  return (
  // <Pagination>
  //   <Pagination.Prev
  //     onClick={() => onPageChange(currentPage - 1)}
  //     disabled={currentPage === 1}
  //   />
  //   {paginationItems}
  //   <Pagination.Next
  //     onClick={() => onPageChange(currentPage + 1)}
  //     disabled={currentPage === totalPages}
  //   />
  // </Pagination>
    <div className="flex justify-between items-center text-sm">
      <div>
        <ul className="flex pl-0 list-none rounded my-2">
          <li
            onClick={() => (pager.currentPage === 1
              ? null
              : onPageChange(pager.currentPage - 1))}
            className="relative  block py-2 px-3 leading-tight bg-white border border-gray-200 text-gray-700 border-r-0 ml-0 rounded-l hover:bg-lightBlue-50"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="cursor-pointer">
              Sebelumnya
            </a>
          </li>

          {/* {paginationItems} */}
          {pager.pages.map((page, index) => (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <PaginationItem
              key={index}
              active={pager.currentPage === page}
              onClick={() => onPageChange(page)}
            >
              {page}
            </PaginationItem>
          ))}
          <li
            onClick={() => (pager.endPage === pager.currentPage
              ? null
              : onPageChange(pager.currentPage + 1))}
            className="relative block py-2 px-3 leading-tight bg-white border border-gray-200 text-gray-700 rounded-r hover:bg-lightBlue-50"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="cursor-pointer">
              Selanjutnya
            </a>
          </li>
        </ul>
      </div>
    </div>

  );
};

const PaginationItem : React.FC<PaginationItems> = ({ children, active, onClick }) => {
  const className = clx(
    'relative block py-2 px-3 leading-tight border  border-r-0 cursor-pointer',
    {
      'bg-lightBlue-500 text-white border-blue-300': active,
      'text-gray-700 hover:bg-lightBlue-50 border-gray-200': !active,
    },
  );
  return (
    <li className={className} onClick={onClick}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>{children}</a>
    </li>
  );
};

interface PaginationItems {
  children?: string;
  active?: boolean;
  onClick?: () => void;
  empty?: boolean;
}

export default PaginationComponent;
