import { Table, Th, Td, SimpleSearchInput, Pagination, PaginationInfo, SelectR } from '@app/components';
import { InfoCard } from '@app/pages/Home/InfoCard';
import { formatNumber } from '@app/utils';
import { Input } from '@app/components/Forms/Input';
import { Calendar } from 'react-modern-calendar-datepicker';
import { useEffect, useState } from 'react';
import { PlusIcon,
  FilterIcon,
  SearchIcon,
  DownloadIcon } from '@heroicons/react/outline';
import { useGetMerchantHistoryTransaction } from '@app/services/merchant-service/report';

export const Reports = () => {
  // const { isLoading, isSuccess, isError, data, error, mutate } = useGetMerchantHistoryTransaction();
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);

  // const [merchantPostData, getMerchantPostData] = useState({
  //   user: {
  //     mid: '',
  //     password: '',
  //   },
  // });

  useEffect(() => {
    // setPayload({ mid: '' });
  });

  // @ts-ignore
  return (
    <div>
      <div className="flex-1 p-4 bg-white rounded-[22px] gap-4 grid grid-cols-2">
        <InfoCard label="Total User" icon="/assets/icon/people-group.svg" value="100" variant="blue" />
        <InfoCard label="Total Balance Of Users" icon="/assets/icon/drugs.svg" value="Rp.25.000.000" variant="green" />
        {/* <InfoCard label="Jumlah Kunjungan Rawat Inap" icons="/assets/icons/hospital-bed.svg" value="100" variant="purple" /> */}
        {/* <InfoCard label="Jumlah Pasien (Populasi yang Aktif Berobat)" icons="/assets/icons/syringe.svg" value="150" variant="orange" /> */}
      </div>
      <div className="p-5">
        <div className="bg-white px-5 border border-gray-200 rounded-lg">
          <div className="flex space-x-2 mt-7 mb-7">
            <Input
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              size="lg"
              variant="outline"
              placeholder="Cari"
              rightIcon={
                <SearchIcon className="w-5 h-5 text-gray-600" />
    }
              tabIndex="0"
            />
          </div>

          <div className="flex space-x-2 mt-7 mb-7">
            <Input
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              size="lg"
              variant="outline"
              placeholder="Cari"
              rightIcon={
                <SearchIcon className="w-5 h-5 text-gray-600" />
                    }
              tabIndex="0"
            />
          </div>

          <Table>
            <thead className="border-t border-b w-full">
              <tr className="h-12 font-semibold">
                <Th columnKey="tes">No.</Th>
                <Th columnKey="tes">Phone</Th>
                <Th columnKey="tes">Balance</Th>
                <Th columnKey="tes">Merchant Cash</Th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr className="border-b border-gray-200 hover:bg-gray-50" key={1}>
                  <Td>1</Td>
                  <Td>{item.hp}</Td>
                  {/* <Td>{item}</Td> */}
                  {/* <Td>{item.merchantCash}</Td> */}
                </tr>
              ))}
            </tbody>
            <div className="p-5 flex justify-between">
              <div className="flex space-x-5 items-center">
                {/* <div className="flex space-x-2"> */}
                {/*  <div className="flex items-center text-sm text-gray-800"> */}
                {/*    Show */}
                {/*  </div> */}
                {/*  <div className="w-20"> */}
                {/*    <SelectR */}
                {/*      onChange={(event) => { */}
                {/*        setPage(1); */}
                {/*        setPageSize(event.target.value); */}
                {/*      }} */}
                {/*      value={pageSize} */}
                {/*    > */}
                {/*      <option value="5">5</option> */}
                {/*      <option value="10">10</option> */}
                {/*      <option value="20">20</option> */}
                {/*    </SelectR> */}
                {/*  </div> */}
                {/* </div> */}
                <PaginationInfo
                  currentPage={page}
                  totalItems={100}
                  pageSize={pageSize}
                />
              </div>
              {/* <Pagination page={payload.page} totalItem={data?.pagination?.total_found || 0} onChange={(p) => setPayload({ page: p })} /> */}
            </div>
          </Table>
        </div>
      </div>
    </div>
  );
};
