import { BaseResponse, instance } from '@app/services/instances';
import { MerchantRequest } from '@app/services/merchant-service/report';
import { GetUserMerchant, getUsersMerchant } from '@app/services/merchant-service/users';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';

export interface GetSettlementReport extends BaseResponse {
  result : {
    information : {
      totalAmount: number,
      adminFee : number,
      settlementAmount: number,
      totalTransaction : number,
      totalDeducted : number
    },
    rows : {
      merchant : string,
      paymentChannel : string,
      referenceId : string,
      transactionDate : string,
      trIdMerchant : string,
      customerName : string,
      va : string,
      amount : number,
      fee : number,
      finalAmount : number,
    }[],
    totalCount: number,
    filteredCount : number
  }
}

export const getSettlementReport = (payload: MerchantRequest): Promise<GetSettlementReport> => {
  return instance.post('/Merchant/MerchantSettlementReport', payload).then((n) => n.data);
};

export const useGetSettlementReport = (defaultPayload: Partial<MerchantRequest> = {}) => {
  const retrievedObject = localStorage.getItem('auth');
  const parsedObject = JSON.parse(retrievedObject);

  const [payload, setPayload] = useSetState<MerchantRequest>({
    merchantToken: parsedObject.token,
    mid: parsedObject.mid,
    pageNumber: 1,
    pageSize: 500000,
    search: '',
    ...defaultPayload,
  });

  const query = useQuery(['settlement-report', [payload]], () => getSettlementReport(payload), { keepPreviousData: true });
  return { ...query, payload, setPayload };
};
