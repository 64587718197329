import clx from 'clsx';
import { classNames } from 'react-select/src/utils';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import { InputElementProps } from '@chakra-ui/react';

interface InputProps {
  // required : required
  id? : string,
  // props?: ReactNode[],
  name? : string,
  type? : string,
  disabled?: boolean,
  error? : any,
  onChange? : any,
  onBlur? : any,
  placeholder: string,
  tabIndex: any,
  leftIcon? : any,
  rightIcon? : any,
  colSpan? : number,
  size?: 'sm' | 'base' | 'lg';
  value?: any
  variant : 'outline' | 'filled' | string,
}

export const Input : React.FC<InputProps> = ({
  error,
  disabled,
  leftIcon,
  rightIcon,
  colSpan = 1,
  size = 'base',
  value = '',
  variant = 'outline',
  ...props
}) => {
  const cl = clx(
    ` col-span-${colSpan} appearance-none transition-all duration-300 ease-in-out rounded  px-3 text-gray-800  leading-tight outline-none  w-full  focus:border-2`,
    {
      'bg-gray-100 cursor-not-allowed': disabled,
      'border border-red-400': error,
      'h-8 text-xs': size === 'sm',
      'h-10 text-sm': size === 'base',
      'h-12 text-base': size === 'lg',

      'pl-10': leftIcon,
      'pr-10': rightIcon,

      'bg-blueGray-100 focus:border focus:border-blueGray-300':
                variant === 'filled' && !error,
      'border border-gray-300 focus:border-blue-500':
                variant === 'outline' && !error,
      'border-none': variant === 'file' && !error,
    },
  );
  return (
    <div className="relative">
      {leftIcon && (
        <div className="absolute text-gray-600 w-10  left-0 top-0   flex justify-center items-center h-full">
          <div className="w-5 h-5">{leftIcon}</div>
        </div>
      )}

      <input disabled={disabled} value={value} className={cl} {...props} />
      {rightIcon && (
      <div className="absolute text-gray-600 w-10  right-0 top-0   flex justify-center items-center h-full">
        <div className="w-5 h-5">{rightIcon}</div>
      </div>
      )}
    </div>
  );
};
