import create from 'zustand';

interface BaseLayoutStore {
  isSlight: boolean;
  setIsSlight: (v: boolean) => void;
  expandedMenu: number | null;
  setExpandedMenu: (key: number | null) => void;
}

export const useBaseLayoutStore = create<BaseLayoutStore>((set) => ({
  isSlight: false,
  setIsSlight: (v) => set({ isSlight: v }),
  expandedMenu: null,
  setExpandedMenu: (key) => set({ expandedMenu: key }),
}));
