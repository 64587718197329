/* eslint-disable max-len */
import clsx from 'clsx';

interface InfoCardProps {
  label: string;
  icon: string;
  value: string;
  variant: 'blue' | 'green' | 'purple' | 'orange';
}

const variants = {
  blue: { bg: '#F0F8FF' },
  green: { bg: '#D7F9EF' },
  purple: { bg: '#E6E2F8' },
  orange: { bg: '#FBF3E9' },
};

export const InfoCard = (props: InfoCardProps) => {
  const variant = variants[props.variant];
  return (
    <div className={clsx('p-4 rounded-[15px] relative overflow-hidden')} style={{ background: variant.bg }}>
      <div className="absolute top-0 right-0 w-20 h-20 bg-cover" style={{ backgroundImage: `url('/assets/svg/union-shape-${props.variant}.svg')` }} />
      <div className="p-2 bg-white w-max rounded-[8px]">
        <img src={props.icon} />
      </div>
      <p className="text-[25px] font-semibold text-gray-600 mt-4">{props.value}</p>
      <p className="text-xs font-medium text-gray-600">{props.label}</p>
    </div>
  );
};
