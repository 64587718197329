import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { AuthLayoutRoute, BaseLayoutRoute } from '@app/layouts';
import { HomePage, AuthPage, NotFoundPage, Settlement } from '@app/pages';
// import { InjectAxiosInterceptors } from './Axios';
import { Users } from '@app/pages/Users';
import DataTable from '@app/pages/Reports/datatable';
import { LogoutPage } from '@app/pages/Auth/logout';
import { CashOutPage } from '@app/pages/CashOut';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      {/* <InjectAxiosInterceptors /> */}
      <Switch>
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <BaseLayoutRoute exact path="/not-found-404" component={NotFoundPage} />
        <BaseLayoutRoute exact path="/settlement-report" component={Settlement} />
        <BaseLayoutRoute exact path="/cash-out" component={CashOutPage} />
        <BaseLayoutRoute exact path="/logout" component={LogoutPage} />
        <AuthLayoutRoute exact path="/login" component={AuthPage} />
        <BaseLayoutRoute exact path="/" component={HomePage} />
        <BaseLayoutRoute exact path="/users" component={Users} />
        <BaseLayoutRoute exact path="/transaction" component={DataTable} />
        <Redirect to="/not-found-404" />
      </Switch>
    </BrowserRouter>
  );
};
