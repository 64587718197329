import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { green, lightBlue, gray } from 'tailwindcss/colors';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';

const theme = extendTheme({
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  colors: {
    blue: lightBlue,
    green,
  },
  components: {
    Input: {
      variants: {
        filled: {
          field: {
            bg: 'white',
            borderRadius: 8,
            _hover: {
              bg: gray[50],
            },
            _focus: {
              bg: 'white',
            },
            _disabled: {
              opacity: 1,
              bg: gray[200],
              cursor: 'default',
              _hover: {
                bg: gray[200],
              },
            },
          },
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Textarea: {
      variants: {
        filled: {
          bg: 'white',
          borderRadius: 8,
          _hover: {
            bg: 'gray.50',
          },
          _focus: {
            bg: 'white',
          },
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Checkbox: {
      baseStyle: {
        label: {
          _disabled: { opacity: 1 },
        },
      },
    },
  },
});

export const CustomChakra: React.FC = ({ children }) => (
  <ChakraProvider theme={theme}>
    {children}
  </ChakraProvider>
);
